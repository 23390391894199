<template>
  <div class="container">
    <div class="row">
      <div class="col-lg-3 col-md-4">
        <MySidebar
          active="addresses"
          :user="user.userName"
          :image="user.image"
        />
      </div>
      <div class="col-lg-9 col-md-8">
        <div class="left-profile p-3 address-card">
          <div class="header-profile">
            <h2>
              {{ $t("Add a new address") }}
            </h2>
          </div>
          <div class="from-all">
            <div class="row">
              <div class="col-md-6">
                <div
                  class="address-add-btn d-flex align-items-center g-0 p-2 mb-4"
                  @click="$router.push('/addmap')"
                >
                  <div class=" ">
                    <img
                      src="../../assets/images/map.svg"
                      class="img-fluid map1 rounded-start"
                      alt=""
                    />
                  </div>
                  <div class="map2">
                    {{ $t("Determine the location on the map") }}
                    {{ body.lat }}, {{ body.lng }}
                  </div>
                  <div class=" ">
                    <img
                      src="../../assets/images/mapLeft.svg"
                      class="img-fluid rounded-start"
                      alt=""
                      style="cursor: pointer"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div class="add-info">
              <h5>{{ $t("Write the address") }}</h5>
              <p class="from-p">
                {{
                  $t(
                    "Fill in the website address data to view delivery charges"
                  )
                }}
              </p>
            </div>

            <form method="post" @submit.prevent="save()">
              <div class="row g-3">
                <div class="col-md-5">
                  <label for="firstName" class="form-label"
                    >{{ $t("firstName") }}
                  </label>
                  <input
                    v-model="body.firstName"
                    :class="{ 'is-invalid': isInvalid && !body.firstName }"
                    class="from-taab form-control"
                    id="firstName"
                    :placeholder="$t('firstName')"
                  />
                </div>
                <div class="col-md-5">
                  <label for="lastName" class="form-label">
                    {{ $t("lastName") }}</label
                  >
                  <input
                    v-model="body.lastName"
                    :class="{ 'is-invalid': isInvalid && !body.lastName }"
                    class="from-taab form-control"
                    id="lastName"
                    :placeholder="$t('lastName')"
                  />
                </div>

                <div class="col-md-5">
                  <label for="phone" class="form-label">{{
                    $t("phone")
                  }}</label>
                  <vue-tel-input
                    :placeholder="$t('Please enter the mobile number')"
                    v-model="body.phone"
                    :class="{ 'is-invalid': isInvalid && !body.phone }"
                    class="from-taab form-control"
                    v-bind="{
                      mode: 'international',
                    }"
                    style="direction: initial"
                  >
                  </vue-tel-input>
                </div>
                <div class="col-md-5">
                  <label for="email" class="form-label">
                    {{ $t("E-mail") }}
                  </label>
                  <input
                    type="email"
                    v-model="body.email"
                    :class="{ 'is-invalid': isInvalid && !body.email }"
                    class="from-taab form-control"
                    id="email"
                    :placeholder="$t('E-mail')"
                  />
                </div>

                <!-- <div class="col-md-6">
                  <label for="countryId" class="form-label"> {{$t("Country")}} </label>

                  <Dropdown
                    v-model="body.countryId"
                    :options="countresList"
                    optionLabel="name.ar"
                    optionValue="_id"
                    :placeholder=" $t('Choose the country')"
                    style="width: 100%"
                    class="from-taab"
                    :filter="true"
                    :showClear="true"
                  />
                </div>
                <div class="col-md-6">
                  <label for="govId" class="form-label"> {{$t("Governorate")}} </label>
                  <Dropdown
                    v-model="body.govId"
                    :options="govesList"
                    optionLabel="name.ar"
                    optionValue="_id"
                    :placeholder="$t('Choose the governorate') "
                    style="width: 100%"
                    class="from-taab"
                    :filter="true"
                    :showClear="true"
                  />
                </div> -->

                <div class="col-md-5">
                  <label for="city" class="form-label">{{ $t("City") }}</label>
                  <!-- <Dropdown v-model="body.cityId" :options="citesList" optionLabel="name.ar" placeholder="$t('Select the city')" /> -->

                  <Dropdown
                    v-model="body.cityId"
                    :options="citesList"
                    optionLabel="name.ar"
                    optionValue="_id"
                    :placeholder="$t('Select the city')"
                    style="width: 100%"
                    class="from-taab"
                    :filter="true"
                    :showClear="true"
                    @change="getRegions($event)"
                  />
                </div>
                <div class="col-md-5">
                  <label for="inputEmail4" class="form-label">{{
                    $t("District")
                  }}</label>

                  <Dropdown
                    v-model="body.regionId"
                    :options="regionesList"
                    optionLabel="name.ar"
                    optionValue="_id"
                    :placeholder="$t('Select the District')"
                    style="width: 100%"
                    class="from-taab"
                    :filter="true"
                    :showClear="true"
                  />
                </div>
                <!-- <div class="col-md-4"></div> -->

                <div class="col-md-5">
                  <label for="inputEmail4" class="form-label">
                    {{ $t("Street/building") }}</label
                  >
                  <input
                    v-model="body.address"
                    :class="{ 'is-invalid': isInvalid && !body.address }"
                    class="from-taab form-control"
                    id="inputEmail4"
                    :placeholder="$t('Street/building')"
                  />
                </div>
                <div class="col-md-5"></div>

                <div class="col-md-5">
                  <button
                    class="btn-addaddress btn mt-4"
                    type="submit"
                    :disabled="disabledAdd"
                  >
                    {{ $t("save") }}
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      user: {
        userName: null,
        email: null,
        phone: null,
        image: null,
      },
      body: {
        govId: null,
        countryId: null,
        cityId: null,
        regionId: null,
        firstName: null,
        lastName: null,
        address: null,
        phone: null,
        email: null,
        lat: null,
        lng: null,
      },
      isInvalid: false,
      disabledAdd: false,
      countresList: [],
      govesList: [],
      citesList: [],
      regionesList: [],
    };
  },
  methods: {
    getRegions(e) {
      if (e.value && e.value != null && e.value != "") {
        this.$http
          .post("region/getRegionByCity?limit=5000", { cityId: e.value })
          .then((res) => {
            this.regionesList = res.data.docs;
          });
      } else {
        this.regionesList = [];
      }
    },
    save() {
      if ((!this.body.lat || !this.body.lng) && !this.body.cityId) {
        this.$toast.add({
          severity: "error",
          summary: this.$t("Error"),
          detail: "You Should choose a location from map",
          life: 3000,
        });

        return;
      }

      if (
        this.body.cityId &&
        this.body.regionId &&
        this.body.firstName &&
        this.body.lastName &&
        this.body.address &&
        this.body.email &&
        this.body.phone
      ) {
        this.disabledAdd = true;

        this.$http.post("address", this.body).then(
          (res) => {
            delete sessionStorage.lat;
            delete sessionStorage.lng;

            this.disabledAdd = false;

            this.$router.push("/address");
            this.$swal({
              title: this.$t("Successfully Done"),
              icon: "success",
              timer: 3000,
              showConfirmButton: false,
            });
          },
          (err) => {
            this.disabledAdd = false;

            this.$toast.add({
              severity: "error",
              summary: this.$t("Error"),
              detail: err.response.data.message,
              life: 3000,
            });
          }
        );
      } else {
        this.isInvalid = true;
        this.$toast.add({
          severity: "error",
          summary: this.$t("Error"),
          detail: this.$t("Please add the required fields"),
          life: 3000,
        });
      }
    },
  },
  created() {
    this.$http.post("users/getUserById", {}).then((res) => {
      this.user = res.data;
    });
    this.$http.get("country?limit=5000").then((res) => {
      this.countresList = res.data.docs;
    });

    if (sessionStorage.lat && sessionStorage.lng) {
      this.body.lat = sessionStorage.lat;
      this.body.lng = sessionStorage.lng;
    }
  },
  mounted() {
    this.$http.get("city?limit=5000").then((res) => {
      this.citesList = res.data.docs;
    });
  },
};
</script>
<style></style>
